import request from '@/utils/request'

/**
 *
 * @returns {Promise<API.Response>}
 */
export function getTenantDictList(code) {
	return request({
		url: '/system/tenantdictionary/list',
		params: {
			code
		}
	})
}

/**
 *
 * @returns {Promise<API.Response>}
 */
export function resetTenantDict(code) {
	return request({
		url: '/system/tenantdictionary/reset',
		params: { code }
	})
}

/**
 *
 * @param {{ code,dictionaryId,state }}params
 * @return {Promise<API.Response>}
 */
export function switchTenantDictState(params) {
	return request({
		url: '/system/tenantdictionary/turnState',
		params
	})
}

/**
 *
 * @param {Core.Numeric[]}ids
 * @return {Promise<API.Response>}
 */
export function sortTenantDictById(ids) {
	return request({
		method: 'POST',
		url: '/system/tenantdictionary/updown',
		data: {
			ids
		}
	})
}

/**
 * 添加单位
 * @param code
 * @param name
 */
export function addUnit(code, name) {
	return request({
		method: 'GET',
		url: '/system/tenantdictionary/addUnit',
		params: {
			code,
			name
		}
	})
}

/**
 * 添加操作步骤
 * @param code
 * @param name
 * @returns {*}
 */
export function addOperationSequence(code, name) {
	return request({
		method: 'GET',
		url: '/system/tenantdictionary/addOperationSequence',
		params: {
			code,
			name
		}
	})
}

const tenantDictionaryAPI = { addUnit ,addOperationSequence}
export default tenantDictionaryAPI
