import request from '@/utils/request'

function saveOrUpdateCategory(data) {
	return request({
		url: '/category/saveOrUpdate',
		method: 'post',
		data
	})
}

function getCategory(query) {
	return request({
		url: '/product/category/get',
		method: 'get',
		params: query
	})
}

/**
 *
 * @param {API.PageParams}data
 * @return {Promise<API.Response<Category.Row[]>>}
 */
function listCategory(data) {
	return request({
		url: '/category/list',
		method: 'post',
		data
	})
}

/**
 *
 * @param {Core.Numeric}parentId
 * @return {Promise<API.Response<Category.Row[]>>}
 */
function listCategoryByParentId(parentId) {
	return request({
		url: '/category/findAllChilren',
		method: 'get',
		params: { parentId }
	})
}

function deleteCategory(id) {
	return request({
		url: '/category/delete',
		method: 'get',
		params: {
			id
		}
	})
}

const addCategory = saveOrUpdateCategory
const updateCategory = saveOrUpdateCategory
export { saveOrUpdateCategory, addCategory, getCategory, listCategory, deleteCategory, updateCategory, listCategoryByParentId }
